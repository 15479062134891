import { msJsClient } from '@/apiClients';
import { CookiesKeys } from '@/constants';
import { useAppStore } from '@/stores/appStore';
import { msQueryClient } from '@wolfejs/ms-api-react/src/client';
import Cookies from 'js-cookie';
import { handleApiClientError } from './errors';

export async function signout() {
  let response;
  try {
    response = await msJsClient.auth.signout();
  } catch (error) {
    handleApiClientError(error);
  }

  const error = response?.error;

  if (error) {
    console.info('Error response from server', error);
    return error;
  }

  // remove frontend created cookies
  Cookies.remove(CookiesKeys.brandId);
  Cookies.remove(CookiesKeys.user);
  Cookies.remove(CookiesKeys.userBusinessData);
  Cookies.remove(CookiesKeys.medusaMerchantToken);
  Cookies.remove(CookiesKeys.medusaCartId);
  Cookies.remove(CookiesKeys.medusaSalesChannelId);

  // remove user object in app store
  useAppStore.getState().setUser(undefined);

  //appContext.setAccessToken?.(undefined);

  // clear out any data that was fetched
  msQueryClient.removeQueries();

  return true;
}
